import request from '@/utils/request'

const userApi = {
  Login: '/login',
  Logout: '/logout',
  Register: '/register',
  // get my info
  UserInfo: '/get-user-info'
}

export function login (accountNo, password, smsCode, uuid, userType, loginType, device, tenantId) {
  const data = {
    accountNo,
    password,
    smsCode,
    uuid,
    userType,
    loginType,
    device,
    tenantId
  }
  return request({
    url: userApi.Login,
    method: 'post',
    data: data
  })
}

// 注册方法
export function register (data) {
  return request({
    url: userApi.Register,
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 获取验证码
export function getCodeImg () {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

// PC端获取验证码
export function getCalidCode (params) {
  return request({
    url: '/get-valid-code',
    method: 'get',
    params
  })
}

// PC端邮箱/手机号验证码登录
export function loginByEmailMobile (data) {
  return request({
    url: '/login-by-email-mobile',
    method: 'post',
    data: data
  })
}

// 重置密码  用户忘记密码时使用
export function resetPassword (data) {
  return request({
    url: '/reset-password',
    method: 'post',
    data: data
  })
}
//查询余额
export function balance (query) {
  return request({
    url: '/system/user/balance',
    method: 'get',
    params: query
  })
}
//资金提现
export function addwithdrawal (query) {
  return request({
    url: '/fund/withdrawal/add',
    method: 'post',
    data: query
  })
}
//资金是否提现
export function withdraw (query) {
  return request({
    url: '/system/user/auto/withdraw',
    method: 'get',
    params: query
  })
}
//获取资金提现配置详情
export function pttxdetail (query) {
  return request({
    url: '/fund/fund-withdrawal-config/detail',
    method: 'get',
    params: query
  })
}

/**
 * @desc 获取平台客服
 * @param {  } 
 * @version: 1.0.0
 */
export function getPtkf (query) {
  return request({
    url: '/system/config/get/ptkf',
    method: 'get'
  })
}
