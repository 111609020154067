import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

/**
 * @desc 电话号码格式化
 * @param {  } 
 * @version: 1.0.0
 */
Vue.filter('PhoneFormat', function (phoneNumber) {
  // 移除所有非数字字符
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  let match = null
  if(cleaned.length === 11){
    match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  }else if(cleaned.length === 10){
    match = cleaned.match(/^(\d{3})(\d{4})(\d{3})$/);
  }
  if (match) {
      return `${match[1]} ${match[2]} ${match[3]}`;
  }
  return null;
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
