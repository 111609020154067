<template>
  <div
    :class="wrpCls"
    style="margin-right: 0;"
  >
    <a-space :size="20">
      <!-- <a-tooltip placement="bottom">
        <template slot="title">
          源码地址
        </template>
        <a-icon type="github" @click="toGithub" :style="{ fontSize: '20px' }"/>
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template slot="title">
          文档地址
        </template>
        <a-icon type="question-circle-o" @click="toDoc" :style="{ fontSize: '20px' }"/>
      </a-tooltip> -->
      <!-- <screenfull v-if="defaultSettings.isShowScreenfull"/> -->
      <!-- 刷新 -->
      <refresh v-if="defaultSettings.isShowRefresh"></refresh>
      <!-- 消息 -->
      <!-- v-hasPermi="['system:notice:list']" -->
      <notice-icon style="cursor: pointer;" v-if="defaultSettings.isShowNoticeIcon"/>
      <!-- 帮助 -->
      <!-- <help v-if="defaultSettings.isShowHelp"></help> -->
      <!-- 暂只支持中文，国际化可自行扩展 -->
      <!-- <select-lang v-if="defaultSettings.isShowSelectLang" :class="prefixCls" /> -->

      <CompaniesList v-if="defaultSettings.isShowCompaniesList"></CompaniesList>
      <avatar-dropdown
        style="background-color: #F6F6F6;"
        :menu="showMenu"
        :current-user="currentUser"
        :class="prefixCls"
      />

    </a-space>
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import NoticeIcon from '@/components/NoticeIcon'
import Screenfull from '@/components/Screenfull'
import SelectLang from '@/components/SelectLang/index.vue'
import MultiTab from '@/components/MultiTab'
import Refresh from '@/components/Refresh'
import Help from '@/components/Help'
import CompaniesList from '@/components/CompaniesList'

import defaultSettings from '@/config/defaultSettings'
export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    NoticeIcon,
    Screenfull,
    SelectLang,
    MultiTab,
    Refresh,
    Help,
    CompaniesList
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      defaultSettings,
      showMenu: true,
      currentUser: {},
      docUrl: process.env.VUE_APP_BASE_API + '/doc',
      githubUrl: 'http://gitlab.niurenjianzhan.com/zhangxun/java-framework'
    }
  },
  computed: {
    wrpCls () {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${(this.isMobile || !this.topMenu) ? 'light' : this.theme}`]: true
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.currentUser = {
        name: 'RuoYi'
      }
    }, 1500)
  },
  methods: {
    toDoc () {
      window.open(this.docUrl)
    },
    toGithub () {
      window.open(this.githubUrl)
    }
  }
}
</script>
