/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class ChamQrcodePlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-qrcode';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '批量下载二维码';
  order=0; // 排序
  description = '批量下载二维码展示信息';
  tags = ['布局容器', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    type: 'cham-qrcode',
    'data_list': 'items',
    qrcode_url:'https://www.chegongdian.cn?sn=${deviceNo}'
  };
  previewSchema = { // 组件面板预览时需要
    type: 'cham-qrcode',
    label: 'cham-qrcode'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title
  panelControls = [ // 右侧属性面板配置项
    {
      type: 'tabs',
      tabsMode: 'line',
      className: 'editor-prop-config-tabs',
      contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
      linksClassName: 'editor-prop-config-tabs-links aa',
      tabs: [
        {
          title: '数据',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'amap_31', 'amap_32'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'amap_31',
                  'header': '绑定字段',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'data_list',
                      label: '数据',
                      value: 'items'
                    },
                    {
                      type: 'ae-textareaFormulaControl',
                      variableMode: 'tree',
                      label: '二维码内容',
                      mode: 'normal',
                      // visibleOn: 'data.wrapperComponent !== undefined',
                      pipeIn: (value, data) => value || (data && data.html),
                      name: 'qrcode_url',
                      tpl: 'https://www.chegongdian.cn?sn=${deviceNo}'
                    }

                  ]
                }
              ]
            }
          ]
        }
      ]

    }

  ];
}

registerEditorPlugin(ChamQrcodePlugin)
