/**
 * 项目默认配置项
 * primaryColor - 默认主题色, 如果修改颜色不生效，请清理 localStorage
 * navTheme - sidebar theme ['dark', 'light'] 两种主题
 * colorWeak - 色盲模式
 * layout - 整体布局方式 ['sidemenu', 'topmenu'] 两种布局
 * fixedHeader - 固定 Header : boolean
 * fixSiderbar - 固定左侧菜单栏 ： boolean
 * contentWidth - 内容区布局： 流式 |  固定
 *
 * storageOptions: {} - Vue-ls 插件配置项 (localStorage/sessionStorage)
 *
 */

// export default {
//   storageKey: 'zzcyi_share_', // 本地缓存前加的key
//   navTheme: 'light', // theme for nav menu  dark  light
//   primaryColor: '#2878FF', // primary color of ant design
//   layout: 'sidemenu', // nav menu position: `sidemenu` or `topmenu`
//   contentWidth: 'Fluid', // layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
//   fixedHeader: true, // sticky header
//   fixSiderbar: true, // sticky siderbar
//   colorWeak: false,
//   multiTab: false,
//   tableSize: 'middle',
//   tableBordered: false,
//   hideFooter: false,
//   title: '物联网后台',
//   production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true',
//   loginType: 7, // 登录页面类型  1,3,4,5,6
//   loginTip: '创新，创造，只为生活更美好',
//   loginTip2: 'Innovation,creation,only for better life',
//   isLoginLeftLogo: false, // 登录页面是否有右上角logo
//   isCodeLogin: false, // 是否支持验证码登录
//   isQQLogin: false, // 是否支持QQ登录
//   isWxLogin: false, // 是否支持微信登录
//   isAliLogin: false, // 是否支持支付宝登录
//   isIphoneLogin: false, // 是否支持苹果登录
//   isLogoFont: true, // 是否显示logo字体
//   sliderVerification:false, // 登录页面是否支持滑块验证码
//   mqttSwitch:true, // mqtt服务开关
//   showMenueTopType:3, // 1 使用带logo和字体的整体图片  2 使用logo图片+配置menuTopTitle文字  3 不使用图片，使用menuTopTitle配置文字
//   menuTopTitle:'中犇科技',
//   isShowMultiTab: false, // 是否显示顶部选项卡
//   isShowScreen: false, // 是否显示顶部数据大屏按钮
//   isShowIotCard: false, // 是否显示顶部物联网卡按钮
//   isShowStore: false, // 是否显示顶部商城按钮
//   isShowScreenfull: true, // 是否显示顶部全屏按钮
//   isShowRefresh: true, // 是否显示顶部刷新按钮
//   isShowNoticeIcon: true, // 是否显示顶部消息按钮
//   isShowHelp: false, // 是否显示顶部帮助按钮
//   isShowSelectLang: true, // 是否显示顶部多语言按钮
//   isShowCompaniesList: false, // 是否显示顶部多租户按钮
//   leftMenuType: 'default', // 左侧菜单类型 default 默认类型的展开菜单 unfold  可展开菜单
//   leftMenuShrink:false, // 是否配置左侧菜单收缩状态

// }

export default {
  storageKey: 'zzcyi_hdcdz_', // 本地缓存前加的key
  tenantId:'1', // 租户id
  navTheme: 'light', // theme for nav menu  dark  light
  primaryColor: '#2878FF', // primary color of ant design
  layout: 'sidemenu', // nav menu position: `sidemenu` or `topmenu`
  contentWidth: 'Fluid', // layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
  fixedHeader: true, // sticky header
  fixSiderbar: true, // sticky siderbar
  colorWeak: false,
  multiTab: false,
  tableSize: 'middle',
  tableBordered: false,
  hideFooter: false,
  // title: '共享充电',
  title: '车公电',
  // title: '中犇科技',
  // gov: '冀ICP备2024064064号-6', //域名备案信息
  gov: '', //域名备案信息
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true',
  loginType: 7, // 登录页面类型  1,3,4,5,6
  loginTip: '创新，创造，只为生活更美好',
  loginTip2: 'Innovation,creation,only for better life',
  isLoginLeftLogo: false, // 登录页面是否有右上角logo
  isCodeLogin: false, // 是否支持验证码登录
  isQQLogin: false, // 是否支持QQ登录
  isWxLogin: false, // 是否支持微信登录
  isAliLogin: false, // 是否支持支付宝登录
  isIphoneLogin: false, // 是否支持苹果登录
  isLogoFont: true, // 是否显示logo字体
  sliderVerification: false, // 登录页面是否支持滑块验证码
  mqttSwitch: false, // mqtt服务开关
  showMenueTopType: 2, // 1 使用带logo和字体的整体图片  2 使用logo图片+配置menuTopTitle文字  3 不使用图片，使用menuTopTitle配置文字 4 单独使用logo
  menuTopTitle: '',
  isShowMultiTab: false, // 是否显示顶部选项卡
  isShowScreen: false, // 是否显示顶部数据大屏按钮
  isShowIotCard: false, // 是否显示顶部物联网卡按钮
  isShowStore: false, // 是否显示顶部商城按钮
  isShowScreenfull: true, // 是否显示顶部全屏按钮
  isShowRefresh: true, // 是否显示顶部刷新按钮
  isShowNoticeIcon: true, // 是否显示顶部消息按钮
  isShowHelp: false, // 是否显示顶部帮助按钮
  isShowSelectLang: true, // 是否显示顶部多语言按钮
  isShowCompaniesList: false, // 是否显示顶部多租户按钮
  leftMenuType: 'default', // 左侧菜单类型 default 默认类型的展开菜单 unfold  可展开菜单
  leftMenuShrink: false, // 是否配置左侧菜单收缩状态
  // 是否支持互联互通
  isNetWork: true, // true 支持 false 不支持

}
